import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { debtorCollectionPriorityFilter } from '@fingo/lib/apollo/reactive-variables/filters';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { KOBROS_COLLECTION_MASTER_ENTITIES } from '@fingo/lib/graphql';
import { getFilterByNameFromHistory } from '@fingo/lib/helpers';
import { useGetCountryFromUrl, useSelectedCompany } from '@fingo/lib/hooks';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import DebtorsActions from '../components/debtors/DebtorsActions';
import {
  HEADERS,
  INITIAL_ORDER_BY,
  INITIAL_PAGE_SIZE,
  ROWS_PER_PAGE_OPTIONS,
  SEARCH_PLACEHOLDER,
  VIEW_TITLE,
  COLUMNS,
  TITLE_INFORMATION_TOOLTIP,
} from '../constants/debtors';

const Debtors = (
  {
    header,
    hideFooter,
    includeHeaders,
    initialPageSize,
    hideReloadButton,
  },
) => {
  const history = useHistory();
  const country = useGetCountryFromUrl();
  const selectedCompany = useSelectedCompany();
  const [debtorCollectionPriority, _setDebtorCollectionPriority] = useState(
    getFilterByNameFromHistory(history, 'debtor_CollectionPriorities_Priority_Value')
      || debtorCollectionPriorityFilter(),
  );
  const setDebtorCollectionPriority = (priorityFilter) => {
    debtorCollectionPriorityFilter(priorityFilter);
    _setDebtorCollectionPriority(priorityFilter);
  };
  return (
    <FingoMainView
      id="debtors-list"
      hideReloadButton={hideReloadButton}
      query={KOBROS_COLLECTION_MASTER_ENTITIES}
      queryCustomVariables={{
        inCollection: selectedCompany.id,
        debtor_CollectionPriorities_Priority_Value: debtorCollectionPriority,
        countryId: country?.id,
      }}
      slots={{
        header,
        table: FingoDataGrid,
        actions: DebtorsActions,
      }}
      slotProps={{
        header: {
          viewTitle: VIEW_TITLE,
          finder: { searchPlaceHolder: SEARCH_PLACEHOLDER },
          informationTooltipText: TITLE_INFORMATION_TOOLTIP,
        },
        table: {
          columns: COLUMNS,
          noRowsMessage: () => <Typography>Sin clientes</Typography>,
          initialOrderBy: INITIAL_ORDER_BY,
          includeHeaders,
          rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
          initialPageSize,
          hideFooter,
        },
        actions: {
          debtorCollectionPriority,
          setDebtorCollectionPriority,
        },
      }}
    />
  );
};

Debtors.propTypes = {
  initialPageSize: PropTypes.number,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  includeHeaders: PropTypes.arrayOf(PropTypes.string),
  hideFooter: PropTypes.bool,
  hideReloadButton: PropTypes.bool,
};

Debtors.defaultProps = {
  initialPageSize: INITIAL_PAGE_SIZE,
  header: PaperHeader,
  includeHeaders: HEADERS,
  hideFooter: false,
  hideReloadButton: false,
};

export default Debtors;
