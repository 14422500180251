import React from 'react';
import PropTypes from 'prop-types';
import ContactCard from '@fingo/lib/components/cards/ContactCard';
import Grid from '@mui/material/Grid';
import { ArrayOfId, MasterEntityType } from '@fingo/lib/propTypes';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import { CONTACT_COLUMNS } from '@fingo/lib/constants/collection-summary';
import DebtorActions from './DebtorActions';
import DebtorSummaryStatistics from './DebtorSummaryStatistics';

const DebtorSummary = ({
  debtor,
  selectedDocumentIds,
  setSelectedDocumentIds,
  showActions,
}) => (
  <Grid
    container
    id="debtor-summary"
    direction="row"
    bgcolor="gray.A100"
    justifyContent="center"
    alignItems="stretch"
    padding={1}
    columns={10}
    width="100%"
    borderRadius={2}
  >
    <Grid
      item
      xs={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <ContactCard
        masterEntity={debtor}
        contactType={CONTACT_TYPES.COLLECTION}
        columns={CONTACT_COLUMNS}
      />
    </Grid>
    <Grid
      item
      xs={6}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <DebtorSummaryStatistics debtor={debtor} sx={{ flex: 1 }} />
    </Grid>
    {showActions && (
      <Grid
        item
        xs={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <DebtorActions
          selectedDocumentIds={selectedDocumentIds}
          setSelectedDocumentIds={setSelectedDocumentIds}
          debtor={debtor}
          sx={{ flex: 1 }}
        />
      </Grid>
    )}
  </Grid>
);

DebtorSummary.propTypes = {
  debtor: MasterEntityType.isRequired,
  selectedDocumentIds: ArrayOfId.isRequired,
  setSelectedDocumentIds: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
};

DebtorSummary.defaultProps = {
  showActions: true,
};

export default DebtorSummary;
