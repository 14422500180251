import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMutation } from '@apollo/client';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { KobrosLogo, SiiLogo } from '@fingo/lib/assets';
import { PasswordInput } from '@fingo/lib/components/inputs';
import {
  ACTIVATE_COLLECTION_PLAN,
  REQUEST_COLLECTION_PLAN_DEMO,
} from '@fingo/lib/graphql/collection/mutations';
import { useSnackBars } from '@fingo/lib/hooks';
import useNationalTaxServiceCredentialsInput from '@fingo/lib/hooks/useNationalTaxServiceCredentialsInput';
import ProgressiveImageBox from '@fingo/lib/components/boxes/ProgressiveImageBox';
import {
  Box,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DEFAULT_COLLECTION_PLAN_ID } from '../../constants/collection';
import { WHATSAPP_LINK } from '../../constants/collection-register';

const SOURCE = 'Fingo';
const ORIGIN = 'kobros';

const CollectionUploadCredentialsStep = () => {
  const [alertAdded, setAlertAdded] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  const { addAlert } = useSnackBars();
  // eslint-disable-next-line operator-linebreak
  const [activateCollectionPlan, { loading: activateCollectionPlanLoading }] =
    useMutation(ACTIVATE_COLLECTION_PLAN, {
      variables: {
        planId: DEFAULT_COLLECTION_PLAN_ID,
      },
      onCompleted: () => {
        history.replace({ pathname: '/app/sales/collection/home' });
      },
    });

  const [
    requestCollectionPlanDemo,
    { data, loading: requestCollectionPlanDemoLoading },
  ] = useMutation(REQUEST_COLLECTION_PLAN_DEMO, {
    onCompleted: () => {
      addAlert({
        id: 'kobros-demo-request',
        message: 'Solicitud enviada exitosamente',
        color: 'success',
      });
    },
  });

  const {
    nationalIdentifier,
    password,
    onChangeInput,
    loading,
    submit,
    errorMessage,
    isValid,
  } = useNationalTaxServiceCredentialsInput(
    SOURCE,
    activateCollectionPlan,
    () => {},
    ORIGIN,
  );

  useEffect(() => {
    if (errorMessage && !alertAdded) {
      addAlert({
        id: 'kobros-upload-credentials-error',
        message: errorMessage,
        color: 'warning',
      });
      setAlertAdded(true);
    }
  }, [errorMessage, alertAdded, addAlert]);

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems="center"
      justifyContent="center"
      height="inherit"
      width="inherit"
    >
      <Stack
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        spacing={2}
        height="100%"
        width="100%"
        pb={{ xs: 0, md: 20 }}
        pl="5%"
        flex={{ xs: 0.35, md: 1 }}
        bgcolor={{ xs: 'white', md: 'gray.A100' }}
      >
        <Stack direction="row" alignItems="center">
          <ProgressiveImageBox
            src={KobrosLogo}
            alt="KobrosLogo"
            sx={{
              maxHeight: 65,
              maxWidth: 'auto',
              objectFit: 'contain',
            }}
          />
          <Divider
            orientation="vertical"
            sx={{ mx: 2, bgcolor: 'black', display: { md: 'none' } }}
            flexItem
          />
          <Box
            component="img"
            sx={{
              maxHeight: 45,
              maxWidth: 'auto',
              objectFit: 'contain',
              display: { md: 'none' },
            }}
            alt="SiiLogo"
            src={SiiLogo}
          />
        </Stack>
        <Typography variant="h1" color="secondary">
          Crea tu cuenta en koBros
        </Typography>
        <Stack spacing={0.5} alignItems={{ xs: 'center', md: 'flex-start' }}>
          <Typography
            variant="h7"
            color="text.primary"
            fontSize={13}
            textAlign="center"
          >
            Para desplegar tu información de clientes
          </Typography>
          <Typography
            variant="h7"
            color="text.primary"
            fontSize={13}
            textAlign="center"
          >
            y que realices gestiones necesitamos tus credenciales del SII
          </Typography>
        </Stack>
      </Stack>
      <Stack
        flex={1}
        direction="column"
        bgcolor="common.white"
        height="100%"
        width="100%"
        justifyContent="flex-start"
        p={3}
        spacing={3.5}
        textAlign="center"
        alignItems="center"
      >
        <Paper
          elevation={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            height: { xs: '60%', md: '70%' },
            width: '90%',
            p: 3,
          }}
        >
          <Stack alignItems="center" spacing={2}>
            <Box
              component="img"
              sx={{
                maxHeight: 45,
                maxWidth: 'auto',
                objectFit: 'contain',
                display: { xs: 'none', md: 'block' },
              }}
              alt="SiiLogo"
              src={SiiLogo}
            />
            <Typography
              variant="h7"
              fontSize={14}
              width={{ xs: '90%', s: '75%', m: '60%' }}
            >
              Ingresa tus credenciales del Servicio de Impuestos Internos
            </Typography>
          </Stack>
          <TextField
            name="nationalIdentifier"
            variant="outlined"
            color="secondary"
            placeholder={`${t('National identifier')} empresa`}
            value={nationalIdentifier}
            onChange={onChangeInput}
            InputProps={{
              sx: {
                borderRadius: 2,
                borderColor: 'secondary',
                height: { xs: 30, md: 45 },
                backgroundColor: 'gray.A100',
                fontWeight: 'bold',
              },
            }}
            sx={{ width: '80%' }}
          />
          <PasswordInput
            Component={TextField}
            name="password"
            type="password"
            variant="outlined"
            color="secondary"
            placeholder={`Clave ${t('National Tax Service acronym')}`}
            value={password}
            iconColor="text.primary"
            onChange={onChangeInput}
            InputProps={{
              sx: {
                borderRadius: 2,
                borderColor: 'secondary',
                height: { sx: 30, md: 45 },
                backgroundColor: 'gray.A100',
                fontWeight: 'bold',
              },
            }}
            sx={{ width: '80%' }}
          />
          <LoadingButton
            id="credentials-create-kobros-account"
            variant="contained"
            color="secondary"
            onClick={() => {
              setAlertAdded(false);
              submit();
            }}
            disabled={!isValid}
            loading={loading || activateCollectionPlanLoading}
            sx={{
              width: { xs: 180, md: 210 },
              height: { xs: 30, md: 40 },
              fontSize: { xs: 12, md: 14 },
              borderRadius: 16,
            }}
          >
            Crear cuenta
          </LoadingButton>
        </Paper>
        <Typography
          variant="h7"
          color="text.primary"
          fontSize={{ xs: 11, md: 13 }}
          width={{ xs: '90%', md: '80%' }}
        >
          Si tienes dudas sobre por qué te pedimos las credenciales del SII, te
          puede contactar un ejecutivo y/o solicitar una demo
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          width="100%"
        >
          <LoadingButton
            id="credentials-kobros-contact-executive"
            variant="outlined"
            color="secondary"
            onClick={() => {
              window.open(WHATSAPP_LINK, '_blank');
            }}
            startIcon={<WhatsAppIcon />}
            sx={{
              width: { xs: 160, md: 210 },
              height: { xs: 30, md: 40 },
              fontSize: { xs: 10, md: 14 },
              borderRadius: 16,
            }}
          >
            Contáctanos
          </LoadingButton>
          <LoadingButton
            id="credentials-kobros-demo-request"
            variant="contained"
            color="secondary"
            onClick={requestCollectionPlanDemo}
            loading={requestCollectionPlanDemoLoading}
            disabled={
              requestCollectionPlanDemoLoading
              || data?.requestCollectionPlanDemo.success
            }
            sx={{
              width: { xs: 160, md: 200 },
              height: { xs: 30, md: 40 },
              fontSize: { xs: 10, md: 14 },
              borderRadius: 16,
            }}
          >
            Solicitar una demo
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CollectionUploadCredentialsStep;
