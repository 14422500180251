import { useTranslation } from 'react-i18next';

const useProfileInfo = ({ setAttributeChanges }) => {
  const { t } = useTranslation();
  const settingAttribute = (attribute) => (
    (newValue) => setAttributeChanges((prev) => {
      if (attribute === 'phoneNumber') return ({ ...prev, [attribute]: `+${t('Phone prefix')}${newValue}` });
      return ({ ...prev, [attribute]: newValue });
    }));
  return [
    {
      title: 'Empresa',
      getAttribute: (user) => user.selectedCompany?.name,
      attributeName: 'companyName',
      category: 'company',
    },
    {
      title: 'Rut empresa',
      getAttribute: (user) => user.selectedCompany?.masterEntity.displayNationalIdentifier,
      attributeName: 'companyRut',
      category: 'company',
    },
    {
      title: 'Nombre',
      getAttribute: (user) => user.firstName,
      attributeName: 'firstName',
      onChangeInput: settingAttribute('firstName'),
      category: 'user',
    },
    {
      title: 'Apellido',
      getAttribute: (user) => user.lastName,
      attributeName: 'lastName',
      onChangeInput: settingAttribute('lastName'),
      category: 'user',
    },
    {
      title: 'Teléfono de contacto',
      verificationAttribute: 'isPhoneVerified',
      getAttribute: (user) => (user.phoneNumber ? user.phoneNumber.slice(3) : ''),
      attributeName: 'phoneNumber',
      onChangeInput: settingAttribute('phoneNumber'),
      type: 'phone',
      category: 'user',
    },
    {
      title: 'Email de contacto',
      verificationAttribute: 'isEmailVerified',
      getAttribute: (user) => user.mailProvided,
      attributeName: 'mailProvided',
      onChangeInput: settingAttribute('mailProvided'),
      type: 'email',
      category: 'company',
    },
  ];
};

export default useProfileInfo;
