import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CollectionSummaryBarChart } from '@fingo/lib/components/collection-summary';
import { collectionSummaryAdaptData,
  INFORMATION_TOOLTIP_TEXT,
} from '@fingo/lib/constants/collection-summary';
import PropTypes from 'prop-types';
import CollectionLoader from './CollectionLoader';
import useCollectionSummary from '../hooks/useCollectionSummary';

const CollectionSummary = () => {
  const {
    collectionManagersByDateRange,
    loading,
  } = useCollectionSummary();

  const countsChartData = collectionSummaryAdaptData(
    collectionManagersByDateRange,
    'count',
  );
  const amountsChartData = collectionSummaryAdaptData(
    collectionManagersByDateRange,
    'amount',
  );

  if (loading) return <CollectionLoader />;

  return (
    <>
      <PaperHeader
        viewTitle="Resumen de cobranza"
        informationTooltipText={INFORMATION_TOOLTIP_TEXT}
      />
      <Grid container xs={12}>
        <Grid item md={12} lg={6}>
          <CollectionSummaryBarChart
            chartData={amountsChartData}
            title="Monto en cartera"
            isMoney
          />
        </Grid>
        <ChartCard
          title="Monto en cartera"
          text="En este gráfico se muestran los montos que tienes en cartera según los plazos de eje x."
        />
      </Grid>
      <Grid container xs={12}>
        <Grid item md={12} lg={6}>
          <CollectionSummaryBarChart
            chartData={countsChartData}
            title="Documentos en cartera"
            legend
          />
        </Grid>
        <ChartCard
          title="Documentos en cartera"
          text="En este gráfico se muestra la cantidad de facturas que tienes en tu cartera según los plazos del eje x."
        />
      </Grid>
    </>
  );
};

const ChartCard = ({ title, text }) => (
  <Grid item md={12} lg={6} container alignItems="center">
    <Box height={120} width={360} bgcolor="gray.A200" p={2} borderRadius={2} margin="auto">
      <Typography variant="h5" color="secondary" mb={1}><b>{title}</b></Typography>
      <Typography variant="h6">
        {text}
      </Typography>
    </Box>
  </Grid>
);

ChartCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CollectionSummary;
