import React from 'react';
import {
  Grid,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import { formatText } from '@fingo/lib/helpers/text-parser';
import { KobrosAskForDemoButton, KobrosAcceptTermsButton, KobrosDownloadGuideButton } from '../../../components/buttons';
import CollectionIconGrid from '../components/CollectionIconGrid';

const CollectionLanding = () => (
  <Box
    paddingX={4}
    paddingY={3}
  >
    <Grid container>
      <Grid item xs={12} md={5}>
        <Stack spacing={2}>
          <Typography>
            <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>koBros es tu mejor {' '}
              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }} component="span" color="secondary.main">
                asesor de cobranza
              </Typography>
            </Typography>
          </Typography>
          <Typography lineHeight={1.5} variant="h2">{formatText('**koBros** es una herramienta que pone a tu disposición un **analista de riesgo con inteligencia artificial** que te ayuda a tomar **mejores decisiones con tus clientes**, cobrar de forma **eficiente** y contar con los **flujos** cuando los necesitas.')}</Typography>
          <Typography lineHeight={1.5} variant="h2">¡Descubre koBros hoy!</Typography>
          <Stack
            sx={{
              paddingTop: 1,
            }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={2}
          >
            <KobrosAcceptTermsButton />
            <KobrosAskForDemoButton text="Solicitar más info" />
            <KobrosDownloadGuideButton text="Manual de uso" />
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        paddingX={{
          xs: 0,
          md: 4,
        }}
        marginY={{
          xs: 3,
          md: 0,
        }}
      >
        <CollectionIconGrid />
      </Grid>
    </Grid>

  </Box>
);

export default CollectionLanding;
