import { addMoneyWithCurrency, formatMoney } from '@fingo/lib/helpers';
import React from 'react';
import {
  CollectionPriorityCell,
  DateWithDaysDiffCell,
} from '@fingo/lib/components/dataGridCells';
import {
  ActionCell,
  ActionDrawerCell,
  LinkCell,
} from '@fingo/lib/components/cells';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import CollectionActions from '@fingo/lib/components/drawer/CollectionActions';
import ProgressBarCell from '@fingo/lib/components/cells/ProgressBarCell';
import dayjs from '@fingo/lib/config/dayjs';

export const VIEW_TITLE = 'Lista de clientes';

export const TITLE_INFORMATION_TOOLTIP = `Visualiza a tus clientes y su información, y fíltralos u 
ordenalos por prioridad. Haz click en cada cliente para ver las facturas en detalle y añadir gestiones.`;

const ACTION_DRAWER_HEADER_TOOLTIP = `En este panel verás las gestiones ya realizadas respecto a 
la cobranza por cliente. Para agregar más gestiones haz click en el nombre del cliente, selecciona 
la factura y luego haz click en el botón "Agregar gestión".`;

export const SEARCH_PLACEHOLDER = 'Buscar cliente';

export const HEADERS = [
  'name',
  'documentsCount',
  'minDateToPay',
  'totalAmount',
  'debtor_CollectionPriorities_Priority_Value',
  'expiredAmount',
  'lastAction',
  'collectionActions',
];

export const MINIMAL_HEADERS = [
  'name',
  'documentsCount',
  'minDateToPay',
  'totalAmount',
];

export const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100];

export const INITIAL_PAGE_SIZE = 25;
export const MINIMAL_INITIAL_PAGE_SIZE = 3;

export const INITIAL_ORDER_BY = 'debtor_CollectionPriorities_Priority_Value';

export const DEFAULT_PRIORITY_SELECT_VALUE = {
  label: 'Prioridades',
  value: null,
};

export const COLUMNS = [
  {
    field: 'name',
    headerName: 'Cliente',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <LinkCell
        to={`/app/debtor/${row.id}`}
        label={row.name}
        caption={row.displayNationalIdentifier}
      />
    ),
  },
  {
    field: 'documentsCount',
    align: 'center',
    headerName: 'Cantidad de facturas',
    headerAlign: 'left',
    type: 'number',
    sortable: false,
    filterable: false,
    minWidth: 165,
    valueGetter: (params) => params.row.pendingCollectionManagers.length,
  },
  {
    field: 'minDateToPay',
    headerName: 'Menor vencimiento',
    type: 'string',
    sortable: false,
    filterable: false,
    minWidth: 150,
    valueGetter: (params) => {
      const orderedMngrs = [...params.row.pendingCollectionManagers].sort(
        (mngr1, mngr2) => dayjs(mngr1?.collectedObjectDateToPay)
          - dayjs(mngr2?.collectedObjectDateToPay),
      );
      return orderedMngrs[0];
    },
    renderCell: ({ value }) => (
      <DateWithDaysDiffCell date={value?.collectedObjectDateToPay} useColors />
    ),
  },
  {
    field: 'totalAmount',
    headerName: 'Total en cobranza',
    type: 'string',
    sortable: false,
    filterable: false,
    minWidth: 145,
    valueGetter: (params) => {
      const managers = params.row.pendingCollectionManagers;
      const value = managers.reduce(
        (prev, curr) => addMoneyWithCurrency(prev, curr.collectedObjectAmount),
        0,
      );
      return `$${formatMoney(value)}`;
    },
  },
  {
    field: 'debtor_CollectionPriorities_Priority_Value',
    headerName: 'Prioridad',
    headerAlign: 'left',
    sortable: true,
    filterable: false,
    minWidth: 125,
    renderCell: ({ row }) => (
      <CollectionPriorityCell
        priority={row.debtor?.collectionPriority?.priority?.value}
      />
    ),
  },
  {
    field: 'expiredAmount',
    headerName: 'Vencido',
    type: 'string',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    maxWidth: 160,
    flex: 1,
    valueGetter: (params) => {
      const managers = params.row.pendingCollectionManagers;
      const totalAmount = managers.reduce(
        (prev, curr) => addMoneyWithCurrency(prev, curr.collectedObjectAmount),
        0,
      );
      const expiredAmount = managers
        .filter((node) => (node ? dayjs(node.collectedObjectDateToPay) < dayjs() : false))
        .reduce(
          (prev, curr) => addMoneyWithCurrency(prev, curr.collectedObjectAmount),
          0,
        );
      return expiredAmount.amount / totalAmount.amount;
    },
    renderCell: (params) => (
      <ProgressBarCell value={params.value ? params.value * 100 : 0} />
    ),
  },
  {
    field: 'lastAction',
    headerName: 'Última gestión',
    type: 'string',
    sortable: false,
    filterable: false,
    minWidth: 160,
    valueGetter: (params) => {
      const actions = params.row.pendingCollectionManagers
        .map((mgr) => mgr.actions)
        .flat();
      const newAc = [...actions].sort(
        (a, b) => dayjs(b.createdAt) - dayjs(a.createdAt),
      );
      return newAc[0];
    },
    renderCell: ({ value }) => <ActionCell action={value} />,
  },
  {
    field: 'collectionActions',
    headerName: 'Gestiones',
    type: 'string',
    sortable: false,
    filterable: false,
    valueGetter: (params) => {
      const actions = params.row.pendingCollectionManagers
        .map((mgr) => mgr.actions)
        .flat();
      return [...actions].sort((a, b) => b.id - a.id);
    },
    renderCell: ({ row, value }) => (
      <ActionDrawerCell
        title={row.name}
        subtitle={row.displayNationalIdentifier}
        headerTooltipText={ACTION_DRAWER_HEADER_TOOLTIP}
        actions={value}
        headerComponent={DrawerHeader}
        contentComponent={CollectionActions}
      />
    ),
  },
];
