import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { ContactList } from '@fingo/lib/components/lists';
import { SelectRadioGroup } from '@fingo/lib/components/selects';
import { useSnackBars, useTextFieldInput } from '@fingo/lib/hooks';
import { ADD_COLLECTION_ACTION } from '@fingo/lib/graphql';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import { ACTIONS_OPTIONS, CONTACT_COLUMNS } from '@fingo/lib/constants/collection-summary';

const useAddCollectionActionSteps = (debtor, documents) => {
  const [actionType, setActionType] = useState();
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [comment, setComment, resetComment] = useTextFieldInput();
  const { addAlert } = useSnackBars();

  const resetFields = useCallback(() => {
    setActionType(null);
    setSelectedContactIds([]);
    resetComment();
  }, []);

  const [addCollectionAction, { loading }] = useMutation(ADD_COLLECTION_ACTION, {
    variables: {
      collectionManagerIds: documents.map(({ collectionManager }) => collectionManager.id),
      collectionContactIds: selectedContactIds,
      actionType,
      comment,
    },
    onCompleted: () => {
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Gestión creada exitosamente',
      });
      resetFields();
    },
  });

  const steps = [
    {
      title: 'Selecciona tipo de gestión',
      component: (
        <Container
          sx={{ width: 800, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <SelectRadioGroup
            options={ACTIONS_OPTIONS}
            setOption={setActionType}
            selectedOption={actionType}
          />
        </Container>
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: !actionType,
        label: 'Siguiente',
      },
    },
    {
      title: 'Selecciona contactos',
      component: (
        <ContactList
          masterEntity={debtor}
          showAllContactTypes
          contactType={CONTACT_TYPES.COLLECTION}
          setSelectedContactIds={setSelectedContactIds}
          selectedContactIds={selectedContactIds}
          columns={CONTACT_COLUMNS}
        />
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: selectedContactIds.length === 0 && actionType !== 'OTHER',
        label: 'Siguiente',
      },
      maxWidth: '70%',
      fullWidth: true,
    },
    {
      title: 'Agrega un comentario',
      component: (
        <Container
          sx={{ width: 800, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <TextField
            id="outlined-basic"
            label="Comentario"
            variant="outlined"
            onChange={setComment}
            value={comment}
            sx={{ m: 2 }}
            multiline
            rows={5}
            fullWidth
          />
        </Container>
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        label: 'Añadir gestión',
        loading,
        handler: addCollectionAction,
      },
    },
  ];

  return [steps, resetFields];
};

export default useAddCollectionActionSteps;
